.driver-check-tree input[data-indeterminate]::before {
  content: "";
  position: absolute;
  width: 8px;
  top: 7px;
  left: 7px;
  height: 2px;
  background-color: hsl(var(--primary)) !important; /* Color of the line */
  background: hsl(var(--primary-foreground)) !important;
  transform: translate(-50%, -50%);
}

.driver-check-tree input[data-indeterminate] {
  position: relative;
  background-color: hsl(var(--primary)) !important;
}

.driver-check-tree .chevron-right {
  background-size: 100%;
  width: 16px;
  height: 16px;
  background-color: hsl(var(--background)) !important;
}
.driver-check-tree .chevron-right.light {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tcmlnaHQiPjxwYXRoIGQ9Im05IDE4IDYtNi02LTYiLz48L3N2Zz4=")
    no-repeat center center;
}

.driver-check-tree .chevron-right.dark {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJibGFjayIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tcmlnaHQiPjxwYXRoIGQ9Im05IDE4IDYtNi02LTYiLz48L3N2Zz4=")
    no-repeat center center;
}

.driver-check-tree .chevron-down {
  background-size: 100%;
  width: 16px;
  height: 16px;
  background-color: hsl(var(--background)) !important;
}

.driver-check-tree .chevron-down.light {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tZG93biI+PHBhdGggZD0ibTYgOSA2IDYgNi02Ii8+PC9zdmc+")
    no-repeat center center;
}

.driver-check-tree .chevron-down.dark {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9Imx1Y2lkZSBsdWNpZGUtY2hldnJvbi1kb3duIj48cGF0aCBkPSJtNiA5IDYgNiA2LTYiLz48L3N2Zz4=")
    no-repeat center center;
}

.driver-check-tree .infinite-tree-toggler {
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.driver-check-tree .infinite-tree-node {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.driver-check-tree .empty {
  margin-left: 7px;
}

.driver-check-tree .infinite-tree-icon svg {
  height: 16px;
}

.vertical-line {
  border-left: 1px solid #ccc;
  position: absolute;
  left: 0;
  top: 20px;
  height: 100%;
  margin-left: 8px;
}

.infinite-tree .infinite-tree-content {
  overflow-y: hidden;
}
.infinite-tree-extra-row {
  display: none;
}
.highlight {
  background-color: #accef7;
}
.dark .highlight {
  background-color: rgba(255, 255, 255, 0.2);
}

.infinite-tree-title {
  background-color: hsl(var(--background)) !important;
}
