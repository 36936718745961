.racer1 {
  position: fixed;
  width: 150px;
  bottom: 0;
  left: -100px;
  & :local {
    animation: moveRight 2s linear infinite;
  }
}

.racer2 {
  position: fixed;
  width: 150px;
  top: 0;
  right: -100px;
  z-index: 1000;
  & :local {
    animation: moveLeft 2s linear infinite;
  }
}

@keyframes moveRight {
  0% {
    left: -100px;
  }
  100% {
    left: 100%;
  }
}

@keyframes moveLeft {
  0% {
    right: -100px;
  }
  100% {
    right: 100%;
  }
}
